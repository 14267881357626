import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  showProgressBar = false;
  profile: any = JSON.parse(localStorage.getItem('profile'));
  paramsId: string;
  imAdmin = false;
  tournament: any;
  quinielas: any[];
  maxPoints = [];
  allMembers = [];
  quinielaFilter: any[];
  param = {value: 0};
  showMax = false;
  totalGoals: 0;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((res: any) => {
      if (res.params.id) {
        this.paramsId = res.params.id;
        this.loadTournament();
      }
    });
  }

  loadTournament() {
    this.showProgressBar = true;
    this.api.details(this.paramsId).subscribe((res: any) => {
    this.showProgressBar = false;
      if (res.tournament) {
        this.tournament = res.tournament;
        this.quinielas = res.quinielas;
        this.quinielaFilter = this.quinielas;
        this.imAdmin = res.imAdmin;
        this.totalGoals = res.totalGoals;
        this.setPoints();
      }
    });
  }

  setPoints() {
    const members = {};
    this.quinielas.forEach((q: any, i: number) => {
      q.points = [];
      q.selected = false;
      q.members.forEach((m: any) => {

        if (members[m.member_id]) {
          // member is in object
          members[m.member_id].sumPoints += m.points || 0;
          members[m.member_id].sumGoals += m.goals || 0;
          members[m.member_id].tablePoints[i] = m.points || null;
          q.points.push(m.points || 0);
        } else {
          // member is not in object
          members[m.member_id] = m;
          members[m.member_id].selected = false;
          members[m.member_id].sumPoints = m.points || 0;
          members[m.member_id].sumGoals = m.goals || 0;
          members[m.member_id].tablePoints = new Array(this.quinielas.length);
          members[m.member_id].tablePoints[i] = m.points || null;
          q.points.push(m.points || 0);
        }
      });
      this.maxPoints.push( this.getMaxOfArray(q.points));
    });
    for (let key in members) {
      members[key].difference = Math.abs(members[key].sumGoals - this.totalGoals);
      this.allMembers.push(members[key]);
    }

    this.sortMembers();
  }

  getMaxOfArray(numArray: any) {
    return Math.max.apply(null, numArray);
  }

  sortMembers() {
    function compare(a: any, b: any) {
      if (a.sumPoints < b.sumPoints) {return 1; }
      if (a.sumPoints > b.sumPoints) {return -1; }
      if (a.difference > b.difference) { return 1; }
      if (a.difference < b.difference) { return -1; }
      if (a.first_name < b.first_name) {return -1; }
      if (a.first_name > b.first_name) {return 1; }
      return 0;
    }
    this.allMembers.sort(compare);
    if (this.tournament.finished && this.allMembers && this.allMembers.length) {
      this.param = { value : this.allMembers[0].sumPoints };
    }
  }

  tapEvent(x: number) {
    this.quinielas[x].selected = !this.quinielas[x].selected;
  }

  tapName(x: number) {
    this.allMembers[x].selected = (this.allMembers[x].selected === true) ? false : true;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  print() {
    const title = this.tournament.name + ' | ' + this.tournament.createdBy.first_name + ' ' + this.tournament.createdBy.last_name + 
      ' | ' + this.tournament.quinielas.length + ' quinielas.';

    let _tournament = {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto'],
        body: [
          ['#', 'Pts', 'Participantes']
        ]
      };

    this.quinielas.forEach((q) => {
      _tournament.widths.push('auto');
      _tournament.body[0].push(q.members[0].quiniela_name);
    });

    if (this.tournament.tiebreaker) {
      _tournament.widths.push('auto');
      _tournament.body[0].push(`Goles \n ${this.totalGoals}`);
    }

    this.allMembers.forEach((m, i) => {
      let memberRow = [];
      memberRow.push({ text: (i + 1).toString(), alignment: 'center' });
      memberRow.push({ text: m.sumPoints, alignment: 'center' });
      memberRow.push((m.first_name + ' ' + m.last_name).slice(0, 10));

      for (let index = 0; index < this.quinielas.length; index++) {
        const point = m.tablePoints[index] ? m.tablePoints[index].toString() : '-';
        const color = (this.maxPoints[index] === m.tablePoints[index] && m.tablePoints[index] !== 0) ?
          'green' : '';
        const bold = this.maxPoints[index] === m.tablePoints[index] && m.tablePoints[index] !== 0;
        memberRow.push({ text: point, alignment: 'center', color: color, bold: bold });
      }

      if (this.tournament.tiebreaker) {
        memberRow.push({ text: `${m.sumGoals} / ${m.difference}`, alignment: 'center' });
      }
      _tournament.body.push(memberRow);
    });

    const docDefinition = {
      pageOrientation: this.quinielas.length > 20 ? 'landscape' : 'portrait',
      pageMargins: [5, 5, 5, 5],
      pageSize: 'LEGAL',
      content: [
        title,
        {
          fontSize: 5,
          layout: 'lightHorizontalLines',
          table: _tournament,
          margin: [0.5, 0.5, 0.5, 0.5]
        },
        {text:'Power by: Quiniela PRO', style: {fontSize: 10}, alignment: 'right', bold: true, margin: [0,15, 0, 1]},
        {text:'www.quinielapro.com', style: {fontSize: 9}, alignment: 'right'},
      ],
    };

    const fileName = this.tournament.name + '_' + this.tournament.createdBy.first_name + '_' + this.tournament.createdBy.last_name;
    pdfMake.createPdf(docDefinition).download(fileName);
  }


}
