import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { QuinielaCtrlService } from 'src/app/providers/controllers/quiniela-ctrl.service';
import { Location } from '@angular/common';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { LoginService } from 'src/app/providers/login.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-quiniela-pdf',
  templateUrl: './quiniela-pdf.component.html',
  styleUrls: ['./quiniela-pdf.component.scss']
})
export class QuinielaPDFComponent implements OnInit {

  quinielaId: string;
  profile: any = null;
  quiniela: any = null;
  jornada: any;
  members: any;
  imAdmin: boolean = false;
  forecasts: Array<any>;
  totalGoals: number;
  me: Array<any>;
  mePos: number = 0;
  imIn: boolean = false;
  imActive: boolean = false;
  infoCoAdmin: any;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  timeOffset: number = new Date().getTimezoneOffset() / 60;
  showCron: boolean = false;
  showProgressBar: boolean = false;
  isPrettyVersion: boolean = false;
  errorPDF: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ctrl: QuinielaCtrlService,
    private location: Location,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
      this.route.paramMap.subscribe((res) => {
      this.quinielaId = res['params'].id;
      this.isPrettyVersion = this.quinielaId.startsWith('pretty');
      if (this.isPrettyVersion) {
        this.quinielaId = this.quinielaId.substring(6, this.quinielaId.length);
      }

      if (localStorage.token) {
        this.profile = JSON.parse(localStorage.profile);
        this.loadQuiniela();
      } else if (res.get('pt')) {
        this.location.replaceState('/quinielapdf');
        this.showProgressBar = true;
        this.loginService.verifyToken(res.get('pt'))
          .then(() => {
            this.profile = JSON.parse(localStorage.profile);
            this.showProgressBar = false;
            this.loadQuiniela();
          }, () => {
            this.showProgressBar = false;
          })

      }
    })

  }

  loadQuiniela() {
    this.showProgressBar = true;
    this.ctrl.init(this.quinielaId, this.profile)
      .subscribe((res) => {
        this.quiniela = res.quiniela;
        this.jornada = res.jornada;
        this.members = res.members;
        this.forecasts = res.forecasts;
        this.totalGoals = res.totalGoals;
        this.me = res.me;
        this.imIn = res.imIn;
        this.imActive = res.imActive;
        this.imAdmin = res.imAdmin;
        this.infoCoAdmin = res.infoCoAdmin;

        if (this.isPrettyVersion) {
          this.prettyPDF();
        } else {
          this.pdf();
        }
      });
  }

  getMatchResult(x: number, y: number) {
    if (x > y) return "L";
    if (x === y) return "E";
    if (x < y) return "V";
  }

  getBase64ImageFromURL(url, isBanner = false) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        if (isBanner) {
          const lazzyURL = 'https://raw.githubusercontent.com/VictorVHTI/imgQPRO/master/Plantillas/lazzy_url.png';
          resolve(this.getBase64ImageFromURL(lazzyURL));
        } else {
          this.showProgressBar = false;
          reject(error);
        }
      };

      img.src = url;
    });
  }

  async prettyPDF() {
    try {
      const useLandscape = this.jornada.matches.length > 16;
      const areLessMatch = this.jornada.matches.length <= 10;
      const isWC = this.jornada.matches.length > 30;
      const isNFL = this.jornada.league === 'NFL';
      const grassColor = '#2CAB80';

      let title = {
        style: {fillColor: '#eeeeee'},
        color: '#444',
        layout: 'noBorders',
        table: {
          widths: ['auto', '*', this.quiniela.notes ? '*' : 'auto'],
          headerRows: 2,
          keepWithHeaderRows: 1,
          body: [
            [ 
              {
                image: await this.getBase64ImageFromURL(this.quiniela.picture, true),
                width: 200,
                height: 113,
                rowSpan: 3,
                margin: [10, 5, 0, 5]
              },
              {
                text: this.quiniela.name, style: {fontSize: 13, bold: true}, margin: [5, 20],
              },
              {
                text: this.quiniela.notes ? 'Notas:\n\n' + this.quiniela.notes : '',
                style:{fontSize: 9},
                rowSpan: 3,
                margin: [5, 5]
              }
            ],
            ['', { text: `Jornada: ${this.jornada.name}`, margin: [5, 10] }],
            ['', { text: `Admin: ${this.quiniela.createdBy.first_name} ${this.quiniela.createdBy.last_name}`,
              alignment: 'right',
              margin: [5, 5],
              style: {fontSize: 10}
            }]
          ]
        }
      };

      let localName = [];
      let foreignName = [];
      let localImage = [];
      let foreignImage = [];
      let widthsHeader = [];
      let members = [];
      const hasStared = this.jornada.matches.some(m => m.done && m.finish);

      const header = (text, center = false) => {
        return {
          text: text,
          bold: true,
          color: 'white',
          fillColor: grassColor,
          alignment: center ? 'center' : 'left',
        };
      }

      for (const m of this.jornada.matches) {
        const fontSize = isWC ? 4.7 : areLessMatch ? 9 : 8;
        const marginImage = isWC ? 2 : 5;
        const width = isWC ? 9 : 20;
        const height = isWC ? 10.5 : 22;
        const colorFill = m.done && m.finish ? grassColor
          : m.done ? '#5FB5FD' : '#eeeeee';

        widthsHeader.push('auto');
        localName.push({
          text: `${m.team_local.acronym} ${m.done ? '\n'+m.result[0] : ''}`,
          style: {fontSize: fontSize},
          margin: [0,0],
          alignment: 'center',
          fillColor: colorFill,
          color: m.done && m.finish ? 'white' : '#444',
        });
        localImage.push(
          {
            image: await this.getBase64ImageFromURL(m.team_local.icon),
            width: width,
            height: height,
            margin: [areLessMatch ? 8 : 0, -marginImage],
            fillColor: colorFill
          },
        );
        foreignName.push({
          text: `${m.team_foreign.acronym} ${m.done ? '\n'+m.result[1]: ''}`,
          style: {fontSize: fontSize},
          margin: [0, marginImage +1],
          alignment: 'center',
          fillColor: colorFill,
          color: m.done && m.finish ? 'white' : '#444',
        });
        foreignImage.push(
          {
            image: await this.getBase64ImageFromURL(m.team_foreign.icon),
            width: width,
            height: height,
            margin: [areLessMatch ? 8 : 0, -((marginImage*2)+2)],
            fillColor: colorFill
          },
        );
      };

      if (this.quiniela.tiebreaker) {
        const fontSize = isWC ? 4.7 : areLessMatch ? 9 : 8;
        widthsHeader.push('auto');
        localName.push(header(''));
        foreignName.push(header(''));
        localImage.push(header(''));
        foreignImage.push({
          text: `${isNFL ? 'Puntos' : 'Goles'}\n${this.totalGoals}`,
          alignment: 'center',
          style: {fontSize: fontSize},
          margin: [0, -15],
          fillColor: grassColor,
          color: 'white'
        });
      }

      this.forecasts.forEach((f, i) => {
        let mRow = [];
        mRow.push({text: (i + 1).toString(), alignment: 'center'});
        mRow.push({ text: f.points, alignment: 'center', style: {bold: true} });
        mRow.push((f.first_name + ' ' + f.last_name).slice(0, 10));
        if (this.quiniela.simple) {
          this.jornada.matches.forEach((m, ii) => {
            mRow.push({ text: f.LEV[ii], color: f.colors[ii], alignment: 'center' });
          });
        }
        else {
          this.jornada.matches.forEach((m, ii) => {
            mRow.push({ text: f.results[ii][0] + '-' + f.results[ii][1], color: f.colors[ii], alignment: 'center' });
          })
        }
        if (this.quiniela.tiebreaker) {
          mRow.push({ text: `${f.numberGoals}${hasStared ? '/'+f.difference : ''}`, alignment: 'center' });
        }

        members.push(mRow);
      });

      const lineWidth = isWC ? 1 : 2.5;
      let contentTable = {
        style: {fillColor: '#eeeeee'},
        color: '#444',
        fontSize: isWC ? 6 : areLessMatch ? 8 : 7,
        layout: {
          hLineWidth: (i, node) =>(i > 3) ? lineWidth : 0,
          vLineWidth: (i, node) => (i > 2) ? lineWidth : 0,
          hLineColor: (i, node) => 'white',
          vLineColor: (i, node) => 'white',
        },
        table: {
          widths: ['auto', 'auto', '*'].concat(widthsHeader),
          headerRows: 4,
          keepWithHeaderRows: 1,
          body: [
            [header(''), header(''), header('')].concat(localName),
            [header(''), header(''), header('')].concat(localImage),
            [header(''), header(''), header('')].concat(foreignName),
            [
              header('#', true),
              header('Pts.'),
              header('Participantes'),
            ].concat(foreignImage),
          ].concat(members)
        }
      };

      var docDefinition = {
        pageOrientation: useLandscape ? 'landscape' : 'portrait',
        pageMargins: [8, 8, 8, 8],
        pageSize: 'LEGAL',
        content: [
          title,
          {text: '', margin: [5, 10], alignment: 'center', style:{fontSize: 9}},
          contentTable,
          {text:'Power by: Quiniela PRO', style: {fontSize: 10}, alignment: 'right', bold: true, margin: [0,15, 0, 1]},
          {text:'www.quinielapro.com', style: {fontSize: 9}, alignment: 'right'},
        ],
      };

      let fileName = this.quiniela.name + "_" + this.quiniela.createdBy.first_name + "_" + this.quiniela.createdBy.last_name;
      pdfMake.createPdf(docDefinition).download(fileName);
      this.showProgressBar = false;
    } catch (error) {
      this.errorPDF = true;
    }
  }

  pdf() {
    let isNFL = this.jornada.league === 'NFL';
    let jName = 'Jornada: ' + this.jornada.name;
    let _jornadaTable = {
      headerRows: 1,
      widths: ['*'],
      body: [
        [''],
        ['Local:  '],
        ['Visita:  ']
      ]
    };
    this.jornada.matches.forEach((m, i) => {
      const isMatchLimit = this.jornada.matches.length > 11;
      _jornadaTable.widths.push('*');
      _jornadaTable.body[0].push((i + 1).toString());
      _jornadaTable.body[1].push(`${isMatchLimit ? m.team_local.acronym : m.team_local.label.substr(0,6).toUpperCase()} ${m.done ? ('[' + m.result[0] + '] ') : ''}`);
      _jornadaTable.body[2].push(`${isMatchLimit ? m.team_foreign.acronym : m.team_foreign.label.substr(0,6).toUpperCase()} ${m.done ? ('[' + m.result[1] + '] ') : ''} `);
    });

    const useLandscape = this.jornada.matches.length > 16;
    let columns = [];
    let nColumns = this.jornada.matches.length > 12 || useLandscape ? 1 :
      this.forecasts.length > 200 ? this.quiniela.simple ? 5 : 4 :
        this.forecasts.length > 100 ? this.quiniela.simple ? 4 : 3 :
          this.forecasts.length > 30 ? 2 : 1;
    let layout = {
      hLineWidth: function (i, node) {
        if (i === 1) return 2;
        return (i === 0 || i === node.table.body.length) ? 0 : 1;
      },
      vLineWidth: function (i, node) {
        return 0;
      },
      paddingLeft: function (i, node) { return .5; },
      paddingRight: function (i, node) { return .5; },
      paddingTop: function (i, node) { return .5; },
      paddingBottom: function (i, node) { return .5; },
    }

    const hasStared = this.jornada.matches.some(m => m.done && m.finish);
    for (let i = 0; i < nColumns; i++) {
      columns.push({
        fontSize: this.jornada.matches.length > 12
          ? 9 
            : this.jornada.matches.length > 30
              ? 7 
              : this.forecasts.length > 100 
                ? 6 
                : this.forecasts.length > 30 
                  ? 9 
                  : 12,
        layout: layout,
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto'],
          body: [
            ['#', 'Pts', 'Participantes']
          ]
        },
        margin: [2, 2, 2, 2]
      })

      this.jornada.matches.forEach((m, jindex) => {
        columns[i].table.widths.push('*');
        columns[i].table.body[0].push({ text: jindex + 1, alignment: 'center' });
      });

      if (this.quiniela.tiebreaker) {
        columns[i].table.widths.push('*');
        columns[i].table.body[0].push({ text: `${isNFL ? 'Puntos ' : ' Goles '}`, alignment: 'center' });
      }

      let set = this.forecasts.slice(i * Math.ceil(this.forecasts.length / nColumns), (i + 1) * Math.ceil(this.forecasts.length / nColumns));
      set.forEach((f, ii) => {
        let memberRow = [];
        memberRow.push((i * Math.ceil(this.forecasts.length / nColumns)) + (ii + 1));
        memberRow.push({ text: f.points, alignment: 'center' });
        memberRow.push((f.first_name + ' ' + f.last_name).slice(0, 10));
        if (this.quiniela.simple) {
          this.jornada.matches.forEach((m, i) => {
            memberRow.push({ text: f.LEV[i], color: f.colors[i], alignment: 'center' });
          });
        }
        else {
          this.jornada.matches.forEach((m, i) => {
            memberRow.push({ text: f.results[i][0] + '-' + f.results[i][1], color: f.colors[i], alignment: 'center' });
          })
        }
        if (this.quiniela.tiebreaker) {
          memberRow.push({ text: `${f.numberGoals}${hasStared ? '/'+f.difference : ''}`, alignment: 'center' });
        }
        columns[i].table.body.push(memberRow);
      });
    }

    let perfectResult = [];
    this.jornada.matches.forEach(m => {
      if (m.finish) {
        perfectResult.push(this.getMatchResult(m.result[0], m.result[1]));
      }
    })
    let title = this.quiniela.name + " | " + this.quiniela.createdBy.first_name + " " + this.quiniela.createdBy.last_name;

    if (this.quiniela.simple && perfectResult.length === this.jornada.matches.length) {
      title = title + "     Combinación ganadora: " + perfectResult.join(" ");
    }

    if (this.quiniela.tiebreaker && perfectResult.length > 0) {
      jName = `${jName} | ${isNFL ? 'Puntos ' : ' Goles '}: ' ${this.totalGoals}`;
    }

    var docDefinition = {
      pageOrientation: useLandscape ? 'landscape' : 'portrait',
      pageMargins: [5, 5, 5, 5],
      pageSize: 'LEGAL',
      content: [
        title,
        { text: jName, fontSize: 9 },
        {
          fontSize: 7,
          layout: layout,
          table: _jornadaTable
        },
        {
          columns: columns
        },
        {text:'Power by: Quiniela PRO', style: {fontSize: 10}, alignment: 'right', bold: true, margin: [0,15, 0, 1]},
        {text:'www.quinielapro.com', style: {fontSize: 9}, alignment: 'right'},
      ],
    };

    let fileName = this.quiniela.name + "_" + this.quiniela.createdBy.first_name + "_" + this.quiniela.createdBy.last_name;
    pdfMake.createPdf(docDefinition).download(fileName);
    this.showProgressBar = false;
  }

}
