import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';

@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.scss']
})
export class TournamentsComponent implements OnInit {

  showArchived = false;
  showProgressBar = false;

  myTournaments: any[] = [];
  allTournaments: any[] = [];
  leagues: any[] = [];
  profile = JSON.parse(localStorage.getItem('profile'));
  imgTournaments = 'https://res.cloudinary.com/moskalti-tech/image/upload/v1532909102/quinielapro/ligas/torneos.jpg';

  constructor(
    private api: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getMyTournament();
    this.loadLeagues();
  }

  getMyTournament() {
    this.showProgressBar = true;
    this.api.imIn().subscribe((res: any) => {
      this.showProgressBar = false;
      if (!res.success) {
        return;
      } else {
        this.allTournaments = res.tournaments;
        this.myTournaments = res.tournaments.filter(tourna => !tourna.finished);
      }
    });
  }

  loadLeagues() {
    this.showProgressBar = true;
    this.api.getAllLeagues().subscribe((res: any) => {
      this.showProgressBar = false;
      if (res.success) {
        this.leagues = res.data;
        this.leagues.forEach(element => {
          element.nameOficial = this.setRealName(element.name);
        });
      }
    });
  }

  setRealName(name: string) {
    let realName = name;
    switch (name) {
      case 'Española':
        realName = 'La Liga';
      break;
      case 'Premier':
        realName = 'Premier League';
      break;
      case 'Champions':
        realName = 'Champions League';
      break;
      default:
        break;
    }
    return realName;
  }

  archivated() {
    this.showArchived = !this.showArchived;
    if (this.showArchived) {
      this.myTournaments = this.allTournaments;
    } else {
      this.myTournaments = this.allTournaments.filter(tourna => !tourna.finished);
    }
  }

  details(id: string, isMine = true, name = '') {
    if (isMine) {
      this.router.navigate(['/tournaments', id]);
    } else {
      this.router.navigate(['/tournaments/official', id, name]);
    }
  }

}
