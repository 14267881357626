import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'confirm-dialog',
    template: `
    <div mat-dialog-content style="line-height: 24px;">
        {{text}}
    </div>
    <!-- Remove forecast -->
    <div *ngIf="isRemoveForecast">
        <div class="delete-forecast">
        <button mat-icon-button color="primary" (click)="forecastToDelete > 1 && forecastToDelete = forecastToDelete - 1;">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        {{forecastToDelete}}
        <button mat-icon-button color="primary" (click)="forecastToDelete < forecast && forecastToDelete = forecastToDelete + 1">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        </div>
    </div>
    <!-- Virtual goals -->
    <div *ngIf="isVirtualGoal">
        <div class="row vg-container">
            <div class="col-xs-12">
                <mat-radio-group [(ngModel)]="action" [ngModelOptions]="{standalone: true}">
                <mat-radio-button value="subtract" style="padding-right: 30px">Restar</mat-radio-button>
                <mat-radio-button value="add">Agregar</mat-radio-button>
            </mat-radio-group>
            </div>
        <div class="col-xs-12 mt-12">
        <mat-form-field class="example-full-width mt-12">
            <mat-label>Goles:</mat-label>
            <input type="number" matInput placeholder="Cantidad" [(ngModel)]="goals" min="1" max="10000">
        </mat-form-field>
        <mat-form-field class="example-full-width mt-12">
            <mat-label>Descripción:</mat-label>
            <input type="text" matInput [(ngModel)]="description">
        </mat-form-field>
        </div>
        </div>
    </div>
    <div mat-dialog-actions class="buttons">
        <button mat-button (click)="onNoClick()" *ngIf="!isInformation">
            {{isRemoveForecast || isVirtualGoal ? 'Cancelar' : 'No'}}
        </button>
        <button [mat-dialog-close]="{success: true, goals: goals, action: action, description: description}" [disabled]="!goals || goals > 10000 "
            *ngIf="isVirtualGoal" mat-button color="primary" cdkFocusInitial>
            Confirmar
        </button>
        <button [mat-dialog-close]="{success:true, forecast: forecastToDelete}"
            *ngIf="!isVirtualGoal" mat-button color="primary" cdkFocusInitial>
            {{isRemoveForecast ? 'Eliminar' : isInformation ? 'Ok' : 'Si'}}
        </button>
    </div>
    `,
    styleUrls: ['./confirm-dialog.scss']
})
export class ConfirmDialog {

    text: string;
    description: string;
    isInformation: boolean;
    isVirtualGoal: boolean;
    action = 'subtract';
    goals = 1;
    isRemoveForecast: boolean;
    forecast: number;
    forecastToDelete = 1;
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.text = data.text;
        this.description = data.description;
        this.isVirtualGoal = data.isVirtualGoal;
        this.isRemoveForecast = data.isRemoveForecast;
        this.forecast = data.forecast ? data.forecast : 0;
        this.isInformation = data.isInformation;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
