import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-quiniela-table',
  templateUrl: './quiniela-table.component.html',
  styleUrls: ['./quiniela-table.component.scss']
})
export class QuinielaTableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  profile = JSON.parse(localStorage.profile);
  forecasts: Array<any>;

  pageSize: number = 20;

  @Input() jornada: any;
  @Input() totalGoals: number;
  @Input() quiniela: any;
  @Input() me: any;
  @Input()
  set _forecasts(forecasts: Array<any>) {
    let realTime = new Date().getTime();
    let startTime = Date.parse(this.jornada.startDate);

    if (startTime > realTime) {
      this.forecasts = (!this.quiniela.hideForecasts && this.me && this.me.length)
        ? forecasts
        : this.me;
    } else {
      this.forecasts = forecasts;
    }

    this.dataSource = new MatTableDataSource(this.forecasts);
    setTimeout(() => { this.dataSource.paginator = this.paginator; });
  }

  displayedColumns: string[] = ['position', 'points', 'first_name', 'matches'];
  dataSource: any;

  constructor() {}

  ngOnInit() {
    if (this.quiniela.tiebreaker) {
      this.displayedColumns.push('difference');
    }
    this.dataSource = new MatTableDataSource(this.forecasts);
    this.dataSource.sort = this.sort;
  }

  getMatchResult(x: number, y: number) {
    if (x > y) return "L";
    if (x === y) return "E";
    if (x < y) return "V";
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  pdf() {
    let isNFL = this.jornada.league === 'NFL';
    let jName = 'Jornada: ' + this.jornada.name;
    let _jornadaTable = {
      headerRows: 1,
      widths: ['*'],
      body: [
        [''],
        ['Local:  '],
        ['Visita:  ']
      ]
    };
    this.jornada.matches.forEach((m, i) => {
      const isMatchLimit = this.jornada.matches.length > 11;
      _jornadaTable.widths.push('*');
      _jornadaTable.body[0].push((i + 1).toString());
      _jornadaTable.body[1].push(`${isMatchLimit ? m.team_local.acronym : m.team_local.label.substr(0,6).toUpperCase()} ${m.done ? ('[' + m.result[0] + '] ') : ''}`);
      _jornadaTable.body[2].push(`${isMatchLimit ? m.team_foreign.acronym : m.team_foreign.label.substr(0,6).toUpperCase()} ${m.done ? ('[' + m.result[1] + '] ') : ''} `);
    });

    const useLandscape = this.jornada.matches.length > 16;
    let columns = [];
    let nColumns = this.jornada.matches.length > 12 || useLandscape ? 1 :
      this.forecasts.length > 200 ? this.quiniela.simple ? 5 : 4 :
        this.forecasts.length > 100 ? this.quiniela.simple ? 4 : 3 :
          this.forecasts.length > 30 ? 2 : 1;
    let layout = {
      hLineWidth: function (i, node) {
        if (i === 1) return 2;
        return (i === 0 || i === node.table.body.length) ? 0 : 1;
      },
      vLineWidth: function (i, node) {
        return 0;
      },
      paddingLeft: function (i, node) { return .5; },
      paddingRight: function (i, node) { return .5; },
      paddingTop: function (i, node) { return .5; },
      paddingBottom: function (i, node) { return .5; },
    }

    const hasStared = this.jornada.matches.some(m => m.done && m.finish);
    for (let i = 0; i < nColumns; i++) {
      columns.push({
        fontSize: this.jornada.matches.length > 12
          ? 9 
            : this.jornada.matches.length > 30
              ? 7 
              : this.forecasts.length > 100 
                ? 6 
                : this.forecasts.length > 30 
                  ? 9 
                  : 12,
        layout: layout,
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto'],
          body: [
            ['#', 'Pts', 'Participantes']
          ]
        },
        margin: [2, 2, 2, 2]
      })

      this.jornada.matches.forEach((m, jindex) => {
        columns[i].table.widths.push('*');
        columns[i].table.body[0].push({ text: jindex + 1, alignment: 'center' });
      });

      if (this.quiniela.tiebreaker) {
        columns[i].table.widths.push('*');
        columns[i].table.body[0].push({ text: `${isNFL ? 'Puntos ' : ' Goles '}`, alignment: 'center' });
      }

      let set = this.forecasts.slice(i * Math.ceil(this.forecasts.length / nColumns), (i + 1) * Math.ceil(this.forecasts.length / nColumns));
      set.forEach((f, ii) => {
        let memberRow = [];
        memberRow.push((i * Math.ceil(this.forecasts.length / nColumns)) + (ii + 1));
        memberRow.push({ text: f.points, alignment: 'center' });
        memberRow.push((f.first_name + ' ' + f.last_name).slice(0, 10));
        if (this.quiniela.simple) {
          this.jornada.matches.forEach((m, i) => {
            memberRow.push({ text: f.LEV[i], color: f.colors[i], alignment: 'center' });
          });
        }
        else {
          this.jornada.matches.forEach((m, i) => {
            memberRow.push({ text: f.results[i][0] + '-' + f.results[i][1], color: f.colors[i], alignment: 'center' });
          })
        }
        if (this.quiniela.tiebreaker) {
          memberRow.push({ text: `${f.numberGoals}${hasStared ? '/'+f.difference : ''}`, alignment: 'center' });
        }
        columns[i].table.body.push(memberRow);
      });
    }

    let perfectResult = [];
    this.jornada.matches.forEach(m => {
      if (m.finish) {
        perfectResult.push(this.getMatchResult(m.result[0], m.result[1]));
      }
    })
    let title = this.quiniela.name + " | " + this.quiniela.createdBy.first_name + " " + this.quiniela.createdBy.last_name;

    if (this.quiniela.simple && perfectResult.length === this.jornada.matches.length) {
      title = title + "     Combinación ganadora: " + perfectResult.join(" ");
    }

    if (this.quiniela.tiebreaker && perfectResult.length > 0) {
      jName = `${jName} | ${isNFL ? 'Puntos ' : ' Goles '}: ' ${this.totalGoals}`;
    }

    var docDefinition = {
      pageOrientation: useLandscape ? 'landscape' : 'portrait',
      pageMargins: [5, 5, 5, 5],
      pageSize: 'LEGAL',
      content: [
        title,
        { text: jName, fontSize: 9 },
        {
          fontSize: 7,
          layout: layout,
          table: _jornadaTable
        },
        {
          columns: columns
        },
        {text:'Power by: Quiniela PRO', style: {fontSize: 10}, alignment: 'right', bold: true, margin: [0,15, 0, 1]},
        {text:'www.quinielapro.com', style: {fontSize: 9}, alignment: 'right'},
      ],
    };

    let fileName = this.quiniela.name + "_" + this.quiniela.createdBy.first_name + "_" + this.quiniela.createdBy.last_name;
    pdfMake.createPdf(docDefinition).download(fileName);
  }

  showMessage() {
    let realTime = new Date().getTime();
    let startTime = Date.parse(this.jornada.startDate);

    if (startTime > realTime) {
      return this.quiniela.hideForecasts || !this.me || this.me.length == 0;
    }

    return false;
  }
}
