import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  _config = {

    ENV: 'PROD',

    version: '1.0.0',

    LOCAL: {
      API: 'http://localhost:2800',
    },
    QA: {
      API: 'https://qa-quinielapro.herokuapp.com'
    },
    PROD: {
      API: 'https://quinielapro.herokuapp.com',
    }

  };

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  config(KEY) {
    if (KEY === 'version') {
      return this._config[KEY];
    }
    return this._config[this._config.ENV][KEY]
  }

  private get(url: string) {
    return this.http.get(this.config('API') + url, { headers: new HttpHeaders({ 'x-access-token': localStorage.getItem('token') }) });
  }

  private post(url: string, params: any) {
    if (localStorage.token) {
      return this.http.post(this.config('API') + url, params, { headers: new HttpHeaders({ 'x-access-token': localStorage.token }) });
    } else {
      return this.http.post(this.config('API') + url, params);
    }

  }

  /* ************ */
  /** Profile */
  /* ************ */
  login(data): any {
    return this.post('/login', data);
  }

  myProfile(): any {
    return this.get('/profile/me');
  }

  /* ************ */
  // settings
  /* ************ */
  settings(setting: string): any {
    return this.get('/settings/details/' + setting);
  }

  /* ************ */
  /** Credist */
  /* ************ */
  myCredits(): any {
    return this.get('/credits/mycredits');
  }

  creditsPrices(): any {
    return this.get('/credits/prices');
  }

  verifyPaypalPayment(data: any): any {
    return this.post('/verify/paypal/payment', data);
  }

  /* ************ */
  /** News */
  /* ************ */
  getNews(): any {
    return this.get('/news/all');
  }

  /* ************ */
  /** Quiniela endpoints */
  /* ************ */
  imin(showArchived): any {
    let url = '/quiniela/imin/' + showArchived;
    return this.get(url);
  }

  quinielaDetails(id: string): any {
    let url = '/quiniela/details/' + id;
    return this.get(url);
  }

  saveForecast(data: any): any {
    const url = '/quiniela/myForecast/';
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          this.snackBar.open('Pronóstico agregado correctamente', '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  deactivateMember(id: string): any {
    const url = '/quiniela/memberstatus/deactivate';
    const data = { id: id };
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          this.snackBar.open('Usuarios deshabilitado', '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  activateMember(id: string): any {
    const url = '/quiniela/memberstatus/activate';
    const data = { id: id };
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          this.snackBar.open('Usuarios habilitado', '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  removeMember(id: string): any {
    const url = '/quiniela/removeMember/';
    const data = { id: id };
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          this.snackBar.open('Usuario Eliminado', '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  updateQMShareCode(data: any): any {
    const url = '/quiniela/updateQMShareCode';
    // const data = { id: id };
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          this.snackBar.open('Usuario Reasignado', '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  removeForecast(data: any): any {
    const url = '/quiniela/removeForecast/';
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          const message = data.num === 1 ? ' pronóstico eliminado.' : ' pronósticos elminados.';
          this.snackBar.open(data.num + message, '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  starMember(id: string): any {
    let url = '/quiniela/starMember';
    let data = { id: id };
    return this.post(url, data);
  }

  myProfiles(): any {
    let url = '/groups/myProfiles';
    return this.get(url);
  }

  deleteProfile(profile_id): any {
    let url = '/groups/deleteP/' + profile_id;
    return this.post(url, {});
  }

  addMembersToQuiniela(data): any {
    let url = '/groups/addGroupMembers';
    return this.post(url, data);
  }
  /* Ends quiniela end points */

  /* ************ */
  /* groups */
  /* ************ */
  createProfile(profile: any): any {
    return this.post('/groups/createProfile', profile);
  }

  /* ************ */
  /* Virual goals */
  /* ************ */

  addGoals(data: any): any {
    const url = '/virtual-goals/addGoals';
    return this.post(url, data).pipe(
      map((response: any) => {
        if (response.success) {
          const message = data.action === 'subtract' ?
            ' goles restados correctamente.' : ' goles agregados correctamente.';
          this.snackBar.open(data.goals + message, '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        return response;
      })
    );
  }

  getMyVirtualGoals(): any {
    return this.get('/virtual-goals/all');
  }

  detailsGoals(id: string): any {
    return this.get('/virtual-goals/details/' + id);
  }

  detailsGoalsUser(id: string): any {
    return this.get('/virtual-goals/details-user/' + id);
  }

  getMembers(): any {
    return this.get('/groups/members');
  }

  /* ************ */
  /* Tournamnets */
  /* ************ */

  getAllLeagues(): any {
    return this.get('/tournament/leagues');
  }

  imIn(): any {
    return this.get('/tournament/imIn');
  }

  getJornadas(data: any): any {
    return this.post('/tournament/jornadasBySeason/', data);
  }

  getTeams(data: any): any {
    return this.post('/tournament/leagueBySeason/', data);
  }

  details(id: string): any {
    return this.get('/api_v2/tournament/' + id);
  }

}
