import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'assign-dialog',
    template: `
    <div mat-dialog-content style="line-height: 24px;">
        Asignar {{text}} a:
    </div>
    <!-- Virtual goals -->
    <div>
        <div class="row vg-container">
            <div class="col-xs-12">
                <mat-radio-group class="row" [(ngModel)]="code" [ngModelOptions]="{standalone: true}">
                    <mat-radio-button class="col-xs-12" *ngFor="let c of coAdmins" value="{{c.shareCode}}">{{c.name}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="buttons">
        <button mat-button (click)="onNoClick()">
            Cancelar
        </button>
        <button [mat-dialog-close]="{success: true, newCode: code}" [disabled]="member.adminInformation.shareCode === code"
            mat-button color="primary" cdkFocusInitial>
            Asignar
        </button>
    </div>
    `,
    styleUrls: ['./assign-dialog.scss']
})
export class AssignDialog {

    text: string;
    code: string;
    member: any;
    coAdmins: Array<any>;
    constructor(
        public dialogRef: MatDialogRef<AssignDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {
        this.text = data.text;
        this.member = data.member,
        this.coAdmins = data.coAdmins;
        this.code = data.code;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
