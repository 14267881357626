import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-virtual-goals',
  templateUrl: './virtual-goals.component.html',
  styleUrls: ['./virtual-goals.component.scss']
})
export class VirtualGoalsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  profile: any = JSON.parse(localStorage.getItem('profile'));
  myVirtualGoals: Array<any> = [];
  myDetails: Array<any> = [];
  myInformation: any;
  myUserActive: Array<any> = [];
  userDetails: Array<any> = [];
  myMembers: Array<any>;
  userInformation: any;
  dataSource: any;
  showProgressBar = false;
  isViewList = true;
  alreadyLoadMembers = false;
  displayedColumns: string[] = ['name', 'action'];
  defaultQuinielaPicture = 'https://res.cloudinary.com/moskalti-tech/image/upload/v1527616891/quinielapro/ligas/QPRO.png';

  constructor(private api: ApiService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getVirtualGoals(true);
  }

  getVirtualGoals(firts = false) {
    this.showProgressBar = true;
    this.api.getMyVirtualGoals().subscribe(res => {
      if (res.success) {
        this.showProgressBar = false;
        this.myVirtualGoals = res.myVirtualGoals;
        this.myUserActive = res.myUserActive;
        if (firts) {
          if (this.myVirtualGoals.length > 0) {
            this.loadMyDetails(this.myVirtualGoals[0]);
          }
          if (this.myUserActive.length > 0) {
            this.sortByName();
            this.loadUserDetails(this.myUserActive[0]);
          }
        }
      }
    });
  }

  loadMyDetails(element: any) {
    this.selectItem(this.myVirtualGoals, element);
    this.showProgressBar = true;
    this.myInformation = {
      quiniela_name: element.quiniela_name,
      goals: element.goals
    };
    this.api.detailsGoals(element._id).subscribe(res => {
      if (res.success) {
        this.myDetails = res.data.reverse();
        this.showProgressBar = false;
      }
    });
  }

  loadUserDetails(element: any) {
    this.selectItem(this.myUserActive, element);
    this.showProgressBar = true;
    this.userInformation = {
      goals: element.goals,
      user_name: element.user_name
    };
    this.api.detailsGoalsUser(element._id).subscribe(res => {
      if (res.success) {
        this.userDetails = res.data.reverse();
        this.showProgressBar = false;
      }
    });
  }

  sortByName() {
    function compare(a: any, b: any) {
      if (a.user_name < b.user_name) {
        return -1;
      }
      if (a.user_name > b.user_name) {
        return 1;
      }
      return 0;
    }
    this.myUserActive.sort(compare);
  }

  selectItem(array: Array<any>, item: any) {
    array.forEach(element => {
      element.isActive = false;
    });
    item.isActive = true;
  }


  addGoals(member: any, isNew = true ) {
    const name = isNew ?  member.first_name + ' ' + member.last_name : member.user_name;
    const dialog = this.dialog.open(ConfirmDialog,
      {
        width: '315px',
        data: {
          isVirtualGoal: true,
          text: 'Agrega o resta Goles Virtuales a ' + name + '.',
          description: 'Restados'
        }
      });

    dialog.afterClosed().subscribe(result => {
      if (result.success) {
        const params = {
            user_id: isNew ?  member._id : member.user_id,
            user_name: name,
            user_picture: isNew ? member.picture.data.url : member.user_picture,
            jornada: result.description,
            admin_id: this.profile._id,
            admin_name: this.profile.first_name + ' ' + this.profile.last_name,
            quiniela_name: this.profile.quiniela_name ? this.profile.quiniela_name : 'Mi quiniela ' + this.profile.first_name,
            quiniela_picture: this.profile.banner ? this.profile.banner : this.defaultQuinielaPicture,
            action: result.action,
            goals: result.goals,
            date: new Date()
        };
        this.api.addGoals(params)
          .subscribe((res: any) => {
          if (res.success) {
            if (!isNew) {
              let arr = [];
              const newGoals = result.action === 'add' ?
                this.userInformation.goals + result.goals : this.userInformation.goals - result.goals;
              this.userInformation.goals = newGoals;
              params.goals = result.action === 'add' ? params.goals : params.goals * -1;
              params.date = new Date();
              arr.push(params);
              this.userDetails = arr.concat(this.userDetails);
              this.myUserActive.forEach(element => {
                if (element.isActive) {
                  element.goals = newGoals;
                }
              });
            } else {
              this.getVirtualGoals(true);
              this.isViewList = true;
            }
          }
        });
      }
    });
  }

  addNewUser() {
    if (!this.alreadyLoadMembers) {
      this.api.getMembers().subscribe((res: any) => {
        this.myMembers = res.data;
        this.myMembers = this.myMembers.sort((a, b) => {
          if (a.first_name > b.first_name) {
            return 1;
          } else if (a.first_name < b.first_name) {
            return -1;
          }
          return 0;
        });
        this.isViewList = false;
        this.alreadyLoadMembers = true;
        this.initDataSource();
      });
    } else {
      this.isViewList = false;
    }
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource(this.myMembers);
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  information() {
    const dialog = this.dialog.open(ConfirmDialog,
      {
        width: '315px',
        data: {
          text: `Goles Virtuales es una Billetera Virtual,
            en la cual puedes agregar o restar goles (pesos) para ayudarte
            a llevar un mejor control de pagos en tus Quinielas.`,
          isInformation: true
        }
      });
  }

}
