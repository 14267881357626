import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile = JSON.parse(localStorage.profile);
  myCredits: number;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.loadMyCredits();
  }

  loadMyCredits() {
    this.api.myCredits()
      .subscribe((res) => {
        if (res.success) {
          this.myCredits = res.data;
        }
      });
  }

}
