import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
@Component({
  selector: 'app-official-details',
  templateUrl: './official-details.component.html',
  styleUrls: ['./official-details.component.scss']
})
export class OfficialDetailsComponent implements OnInit {

  showProgressBar = false;
  timeOffset: number = new Date().getTimezoneOffset() / 60;
  leagueInfo: any;
  season: any;
  jornadas: any;
  LeagueDatails: any;
  teams: any;
  showGroups = false;
  groups: any[] = [];
  dateMatches = [];
  getDays = [];
  easterEgg = [];
  torneoName: string;

  showJornada: any;
  jornadaLeague: any;
  availableJornadas = false;
  already = false;
  fase = '';
  name = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  constructor(
    private api: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((res: any) => {
      if (res.params.id && res.params.name) {
        this.torneoName = this.setRealName(res.params.name);
        this.leagueInfo = {
          name: res.params.name,
          id: res.params.id,
          changeSeason: false,
          seasonValue: ''
        };
        this.loadJornadas();
      }
    });
  }

  loadJornadas() {
    const params = {
      nameJornda: this.leagueInfo.name,
      change_season: this.leagueInfo.changeSeason,
      season: this.leagueInfo.seasonValue
    };
    this.showProgressBar = true;
    this.api.getJornadas(params).subscribe((res: any) => {
      this.jornadas = res.jornadas.reverse();
      this.season = res.season.reverse();
      if (!this.leagueInfo.changeSeason) {
        this.leagueInfo.seasonValue = this.season[0];
      }
      this.loadTeams();
      this.showProgressBar = false;
    });
  }

  loadTeams() {
    this.showProgressBar = true;
    const params = {
      id: this.leagueInfo.id,
      season: this.leagueInfo.seasonValue
    };

    this.api.getTeams(params).subscribe((res: any) => {
      this.showProgressBar = false;
      this.LeagueDatails = res.data;
      this.teams = res.data.teams;
      this.setInitialization();
    }
    );

  }

  setInitialization() {
    if (!this.LeagueDatails.groupsPhase) {
      this.showGroups = true;
      for (const m of this.teams) {
        m.numbers = [0, 0, 0, 0, 0, 0, 0, 0];
      }
      this.setPoints();
    } else {
      for (const m of this.LeagueDatails.groups) {
        for (let n = 0; n < m.length; n++) {
          for (const p of this.teams) {
            if (m[n] === p.name) {
              m[n] = {
                name: p.name,
                image: p.icon,
                numbers: [0, 0, 0, 0, 0, 0, 0, 0]
              };
            }
          }
        }
      }
      this.groups = this.LeagueDatails.groups;
      this.setPointsGroups();
    }
  }

  setPointsGroups() {
    let flag = false;
    this.showJornada = {};
    this.jornadaLeague = this.jornadas;
    this.availableJornadas = false;
    if (this.jornadaLeague.length > 0) { flag = true; }
    if (flag) {
      this.availableJornadas = true;
      this.fase = this.jornadaLeague[0]._id;
      this.showJornada = this.jornadaLeague[0];
      this.already = true;
      this.showFormatDate();
      let y = 0;

      for (const k of this.groups) {
        for (let l of k) {
          let J = 0, G = 0, P = 0, E = 0, GA = 0, GC = 0, DG = 0, pts = 0;
          for (const m of this.jornadaLeague) {
            if (m.phase === 'regular') {
              for (const t of m.matches) {
                if (t.finish) {
                  if (t.team_local.name === l.name) {
                    J = J + 1;
                    if (t.result[0] > t.result[1]) {
                      G = G + 1;
                      pts = pts + 3;
                    }
                    if (t.result[0] === t.result[1]) {
                      E = E + 1;
                      pts = pts + 1;
                    }
                    if (t.result[0] < t.result[1]) {
                      P = P + 1;
                    }
                    GA = t.result[0] + GA;
                    GC = t.result[1] + GC;
                    DG = (t.result[0] - t.result[1]) + DG;
                  }

                  if (t.team_foreign.name === l.name) {
                    J = J + 1;
                    if (t.result[0] < t.result[1]) {
                      G = G + 1;
                      pts = pts + 3;
                    }
                    if (t.result[0] === t.result[1]) {
                      E = E + 1;
                      pts = pts + 1;
                    }
                    if (t.result[0] > t.result[1]) {
                      P = P + 1;
                    }
                    GA = t.result[1] + GA;
                    GC = t.result[0] + GC;
                    DG = (t.result[1] - t.result[0]) + DG;

                  }
                }
              }
              l.numbers = [pts, J, G, E, P, GA, GC, DG];
            }
          }
        }
        this.sortTeamsGroups(y);
        y = y + 1;
      }
    }
  }

  setPoints() {
    let flag = false;
    this.showJornada = {};
    this.jornadaLeague = this.jornadas;
    this.availableJornadas = false;
    if (this.jornadaLeague.length > 0) { flag = true; }

    if (flag) {
      this.availableJornadas = true;
      this.fase = this.jornadaLeague[0]._id;
      this.showJornada = this.jornadaLeague[0];
      this.already = true;
      this.showFormatDate();

      for (let l of this.teams) {
        let J = 0, G = 0, P = 0, E = 0, GA = 0, GC = 0, DG = 0, pts = 0;
        for (const m of this.jornadaLeague) {
          if (m.phase === 'regular') {
            for (const t of m.matches) {
              if (t.finish) {
                if (t.team_local.name === l.name) {
                  J = J + 1;
                  if (t.result[0] > t.result[1]) {
                    G = G + 1;
                    pts = pts + 3;
                  }
                  if (t.result[0] === t.result[1]) {
                    E = E + 1;
                    pts = pts + 1;
                  }
                  if (t.result[0] < t.result[1]) {
                    P = P + 1;
                  }
                  GA = t.result[0] + GA;
                  GC = t.result[1] + GC;
                  DG = (t.result[0] - t.result[1]) + DG;
                }

                if (t.team_foreign.name === l.name) {
                  J = J + 1;
                  if (t.result[0] < t.result[1]) {
                    G = G + 1;
                    pts = pts + 3;
                  }
                  if (t.result[0] === t.result[1]) {
                    E = E + 1;
                    pts = pts + 1;
                  }
                  if (t.result[0] > t.result[1]) {
                    P = P + 1;
                  }
                  GA = t.result[1] + GA;
                  GC = t.result[0] + GC;
                  DG = (t.result[1] - t.result[0]) + DG;
                }
              }
            }
            l.numbers = [pts, J, G, E, P, GA, GC, DG];
          }
        }
      }
    }
    this.sortTeams();
  }

  showFormatDate() {
    let i = 0;
    for (const w of this.showJornada.matches) {
      const dateNew = new Date(w.date);
      dateNew.setHours(dateNew.getHours() + this.timeOffset);
      this.dateMatches[i] = dateNew.toISOString();
      // this.getDays[i] =
      //   {
      //     fecha : this.getDAYS( dateNew.getDay() ) + " " + dateNew.getDate() + " de " + this.getMONTH( dateNew.getMonth() ) ,
      //     date : this.getDAYS( dateNew.getDay() ) + ", " + this.getMONTH( dateNew.getMonth() ) + " " + dateNew.getDate()
      //   };
      if ( dateNew.getMinutes() === 33) {
        this.easterEgg[i] = true;
      } else {
        this.easterEgg[i] = false;
      }
      i++;
    }
  }

  show() {

    this.showJornada = this.jornadaLeague.find(element => element._id === this.fase);
    this.already = true;
    this.showFormatDate();
  }

  sortTeams() {
    function compare(a: any, b: any) {
      if (a.numbers[0] < b.numbers[0]) { // PTS
        return 1; }
      if (a.numbers[0] > b.numbers[0]) {
        return -1; }
      if (a.numbers[7] < b.numbers[7]) { // DG
        return 1; }
      if (a.numbers[7] > b.numbers[7]) {
        return -1; }
      if (a.numbers[5] < b.numbers[5]) { // GA
        return 1; }
      if (a.numbers[5] > b.numbers[5]) {
        return -1; }
      if (a.numbers[6] < b.numbers[6]) { // GC
        return 1; }
      if (a.numbers[6] > b.numbers[6]) {
        return -1; }
      return 0;
    }
    this.teams.sort(compare);
  }

  sortTeamsGroups(x) {
    function compare(a: any, b: any) {
      if (a.numbers[0] < b.numbers[0]) { // PTS
        return 1; }
      if (a.numbers[0] > b.numbers[0]) {
        return -1; }
      if (a.numbers[7] < b.numbers[7]) { // DG
        return 1; }
      if (a.numbers[7] > b.numbers[7]) {
        return -1; }
      if (a.numbers[5] < b.numbers[5]) { // GA
        return 1; }
      if (a.numbers[5] > b.numbers[5]) {
        return -1; }
      if (a.numbers[6] < b.numbers[6]) { // GC
        return 1; }
      if (a.numbers[6] > b.numbers[6]) {
        return -1; }
      return 0;
    }
    this.groups[x].sort(compare);
  }

  setRealName(name: string) {
    let realName = name;
    switch (name) {
      case 'Española':
        realName = 'La Liga';
      break;
      case 'Premier':
        realName = 'Premier League';
      break;
      case 'Champions':
        realName = 'Champions League';
      break;
      default:
        break;
    }
    return realName;
  }

}
