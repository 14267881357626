import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';

import { ApiService } from '../../../providers/api/api.service';
import { AddMemberComponent } from '../add-member/add-member.component';
import { FillForecastComponent } from '../../fill-forecast/fill-forecast.component';
import { ConfirmDialog } from '../../confirm-dialog/confirm-dialog';
import { AssignDialog } from '../../assign-dialog/assign-dialog';

@Component({
  selector: 'app-quiniela-admin',
  templateUrl: './quiniela-admin.component.html',
  styleUrls: ['./quiniela-admin.component.scss']
})
export class QuinielaAdminComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  profile = JSON.parse(localStorage.profile);
  defaultQuinielaPicture = 'https://res.cloudinary.com/moskalti-tech/image/upload/v1527616891/quinielapro/ligas/QPRO.png';
  members: Array<any>;
  myProfileAlphab: Array<any>;
  membersFilter: Array<any>;

  forecastsLength: number;
  limit: number;
  inProgress: boolean;
  filterByCoAdmin = false;
  coAdminInformation = false;
  totalAdminForecast = 0;
  pos = 1;

  @Input() quiniela: any;
  @Input() jornada: any;
  @Input() imAdmin: boolean;
  @Input() coAdmin: any;
  @Input()
  set _members(members: Array<any>) {
    this.members = members;
    this.membersFilter = members;
    this.myProfileAlphab = members;
    this.dataSource = new MatTableDataSource(this.members);
  }
  @Input()
  set _forecastsLength(length: number) {
    this.forecastsLength = length;
  }
  @Input()
  set _limit(limit: number) {
    this.limit = limit;
  }
  @Input()
  set _inProgress(inProgress: boolean) {
    this.inProgress = inProgress;
  }
  @Output() quinielaReload = new EventEmitter<any>();

  displayedColumns: string[] = ['first_name', 'starred', 'forecasts', 'actions'];
  dataSource: any;

  constructor(private api: ApiService, private dialog: MatDialog) {

  }

  star(member: any) {
    if (this.validationAction(member)) {
      member.starred = !member.starred;
      this.api.starMember(member._id).subscribe();
    }
  }

  addMemberDialog() {
    const dialog = this.dialog.open(AddMemberComponent,
      {
        minWidth: '500px',
        minHeight: '90vh',
        data: {
          quiniela: this.quiniela
        }
      });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.quinielaReload.emit();
      }
    });
  }

  addForecast(member: any) {
    const dialog = this.dialog.open(FillForecastComponent,
      {
        data: {
          member: member,
          quiniela: this.quiniela,
          jornada: this.jornada,
          isByUser: true
        }
      });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.quinielaReload.emit();
      }
    });
  }

  virtualGoals(member: any) {
    const dialog = this.dialog.open(ConfirmDialog,
      {
        width: '315px',
        data: {
          isVirtualGoal: true,
          text: 'Agrega o resta Goles Virtuales a ' + member.first_name + member.last_name + '.',
          description: this.quiniela.jornada_name,
        }
      });

    dialog.afterClosed().subscribe(result => {
      if (result.success) {
        const params = {
            user_id: member.member_id,
            user_name: member.first_name + ' ' + member.last_name,
            user_picture: member.picture.data.url,
            jornada: result.description,
            admin_id: this.profile._id,
            admin_name: this.profile.first_name + ' ' + this.profile.last_name,
            quiniela_name: this.profile.quiniela_name ? this.profile.quiniela_name : 'Mi quiniela ' + this.profile.first_name,
            quiniela_picture: this.profile.banner ? this.profile.banner : this.defaultQuinielaPicture,
            action: result.action,
            goals: result.goals
        };
        this.api.addGoals(params)
          .subscribe((res: any) => {
          if (res.success) {
          }
        });
      }
    });
  }

  memberAction(member: any, action: string) {
    let message: string;

    switch (action) {
      case 'disable':
        message = '¿Deshabilitar pronósticos de ' + member.first_name + ' ' + member.last_name + '?';
        break;
      case 'enable':
        message = 'Habilitar pronósticos de ' + member.first_name + ' ' + member.last_name + '?';
        break;
      case 'remove':
        message = `¿Eliminar a ` + member.first_name + ' ' + member.last_name + ` de tu Quiniela?
          Esta acción es permanente, el usuario no podrá unirse de nuevo.`;
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: {
        text: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        switch (action) {
          case 'disable':
            this.api.deactivateMember(member._id)
            .subscribe((res: any) => {
              if (res.success) {
                member.active = false;
                this.quinielaReload.emit();
              }
            });
            break;
          case 'enable':
            this.api.activateMember(member._id)
            .subscribe((res: any) => {
              if (res.success) {
                member.active = true;
                this.quinielaReload.emit();
              }
            });
            break;
          case 'remove':
            this.api.removeMember(member._id)
            .subscribe((res: any) => {
              if (res.success) {
                member.removed = true;
                this.quinielaReload.emit();
              }
            });
            break;
          default:
            break;
        }
      }
    });
  }

  removeForecast(member: any) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '315px',
      data: {
        text: 'Elinimar últimos pronósticos de: ' + member.first_name + ' ' + member.last_name + '.',
        isRemoveForecast: true,
        forecast: member.results.length
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        const params = {
          id: member._id,
          num: result.forecast
        };
        this.api.removeForecast(params)
          .subscribe((res: any) => {
          if (res.success) {
            member.results = res.data.results;
            this.quinielaReload.emit();
          }
        });
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.members);
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.filterByCoAdmin = false;
    this.dataSource = new MatTableDataSource(this.members);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  orderMembers() {
    switch (this.pos) {
      case 1:
        this.sortByName();
        break;
      case 2:
        this.sortByForecast();
        break;
      case 3:
        this.sortByTime();
        this.pos = 0;
        break;
      default: break;
    }
    this.pos += 1;
  }

  sortByName() {
    function compare(a: any, b: any) {
      if (a.first_name < b.first_name) { return -1; }
      if (a.first_name > b.first_name) { return 1; }
      return 0;
    }
    this.myProfileAlphab.sort(compare);
    this.dataSource = new MatTableDataSource(this.myProfileAlphab);
  }

  sortByForecast() {
    function compare(a: any, b: any ) {
      if (a.results.length < b.results.length) { return -1; }
      if (a.results.length > b.results.length) { return 1; }
      if (a.first_name > b.first_name) { return -1; }
      if (a.first_name < b.first_name) { return 1; }
      return 0;
    }
    this.myProfileAlphab.sort(compare);
    this.dataSource = new MatTableDataSource(this.myProfileAlphab.reverse());
  }

  sortByTime() {
    function compare(a: any, b: any) {
      if (a._id > b._id) { return -1; }
      if (a._id < b._id) { return 1; }
      return 0;
    }
    this.myProfileAlphab.sort(compare);
    this.dataSource = new MatTableDataSource(this.myProfileAlphab.reverse());
  }

  showCoAdminInformation() {
    this.coAdminInformation = !this.coAdminInformation;
    this.filterByCoAdmin = false;
    if (this.coAdminInformation) {
      const arr = [];
      arr.push(this.members.find(m => m.member_id === this.quiniela.createdBy.id));
      this.quiniela.coAdmin.forEach(ca => {
        arr.push(this.members.find(m => m.member_id === ca.coAdmin_id));
      });
      arr.forEach(element => {
        let arrMember = [];
        arrMember = this.members.filter(m => m.adminInformation.shareCode === element.adminInformation.shareCode);
        element.totalMembers = arrMember.length;
        element.totalForecast = 0;
        arrMember.forEach(m => {
          if (m.active && !m.removed) {
            element.totalForecast = m.results.length + element.totalForecast;
          }
        });
      this.dataSource = new MatTableDataSource(arr);
      });
    } else {
      this.dataSource = new MatTableDataSource(this.members);
    }
  }

  validationAction(member) {
    return !member.removed && (this.imAdmin || ((this.quiniela.enableCoAdminFuction && !this.coAdmin.isDeleted) && (this.coAdmin.canEditAllUsers || member.adminInformation.shareCode === this.coAdmin.myShareCode)));
  }

  presentRadioAlert(member: any) {

    const arrAdmin = [
      {
        shareCode: typeof this.quiniela.shareCode === 'string' ? this.quiniela.shareCode : this.quiniela.shareCode[0],
        name: this.quiniela.createdBy.first_name + ' ' + this.quiniela.createdBy.last_name,
      }
    ];
    const dialogRef = this.dialog.open(AssignDialog, {
      width: '415px',
      data: {
        text: member.first_name + ' ' + member.last_name,
        member: member,
        code: member.adminInformation.shareCode,
        coAdmins: arrAdmin.concat(this.quiniela.coAdmin)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        const params = {
          qm_id: member._id,
          shareCode: result.newCode
        };
        this.api.updateQMShareCode(params)
          .subscribe((res: any) => {
          if (res.success) {
            member.shareCodeAdmin = result.newCode;
            this.quinielaReload.emit();
          }
        });
      }
    });
  }

  byCoAdmin(admin: any) {
    if (!this.coAdminInformation) {
      this.filterByCoAdmin = !this.filterByCoAdmin;
      let arr = [];

      if (this.filterByCoAdmin) {
        this.totalAdminForecast = 0;
        arr = this.members.filter(m => m.adminInformation.shareCode === admin.adminInformation.shareCode);
        arr.forEach(m => {
          if (m.active && !m.removed) {
            this.totalAdminForecast = m.results.length + this.totalAdminForecast;
          }
        });
        this.dataSource = new MatTableDataSource(arr);
      } else {
        this.dataSource = new MatTableDataSource(this.members);
      }
    }
  }

}
