import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
import { Location } from '@angular/common';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { LoginService } from 'src/app/providers/login.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-quiniela-pdf',
  templateUrl: './tournament-pdf.component.html',
  styleUrls: ['./tournament-pdf.component.scss']
})
export class TournamentPDFComponent implements OnInit {

  profile: any = null;
  showProgressBar = false;
  paramsId: string;
  imAdmin = false;
  tournament: any;
  quinielas: any[];
  maxPoints = [];
  allMembers = [];
  quinielaFilter: any[];
  param = {value: 0};
  showMax = false;
  tournamentId = '';
  isPrettyVersion: boolean = false;
  errorPDF: boolean = false;
  totalGoals: 0;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private loginService: LoginService,
    private api: ApiService,
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe((res) => {
      let id = res['params'].id;
      this.isPrettyVersion = res['params'].vers == 'true';

      if (localStorage.token) {
        this.profile = JSON.parse(localStorage.profile);
        this.loadTournament(id);
      } else if (res.get('pt')) {
        this.location.replaceState('/tournamentpdf');
        this.showProgressBar = true;
        this.loginService.verifyToken(res.get('pt'))
          .then(() => {
            this.profile = JSON.parse(localStorage.profile);
            this.showProgressBar = false;
            this.loadTournament(id);
          }, () => {
            this.showProgressBar = false;
          })

      }
    })

  }

  loadTournament(id: string) {
    this.showProgressBar = true;
    this.api.details(id).subscribe((res: any) => {
      if (res.tournament) {
        this.tournament = res.tournament;
        this.quinielas = res.quinielas;
        this.quinielaFilter = this.quinielas;
        this.imAdmin = res.imAdmin;
        this.totalGoals = res.totalGoals;
        this.setPoints();
      }
    });
  }


  setPoints() {
    const members = {};
    this.quinielas.forEach((q: any, i: number) => {
      q.points = [];
      q.selected = false;
      q.members.forEach((m: any) => {

        if (members[m.member_id]) {
          // member is in object
          members[m.member_id].sumPoints += m.points || 0;
          members[m.member_id].sumGoals += m.goals || 0;
          members[m.member_id].tablePoints[i] = m.points || null;
          q.points.push(m.points || 0);
        } else {
          // member is not in object
          members[m.member_id] = m;
          members[m.member_id].selected = false;
          members[m.member_id].sumPoints = m.points || 0;
          members[m.member_id].sumGoals = m.goals || 0;
          members[m.member_id].tablePoints = new Array(this.quinielas.length);
          members[m.member_id].tablePoints[i] = m.points || null;
          q.points.push(m.points || 0);
        }
      });
      this.maxPoints.push( this.getMaxOfArray(q.points));
    });
    for (let key in members) {
      members[key].difference = Math.abs(members[key].sumGoals - this.totalGoals);
      this.allMembers.push(members[key]);
    }

    this.sortMembers();
  }

  getMaxOfArray(numArray: any) {
    return Math.max.apply(null, numArray);
  }

  sortMembers() {
    function compare(a: any, b: any) {
      if (a.sumPoints < b.sumPoints) {return 1; }
      if (a.sumPoints > b.sumPoints) {return -1; }
      if (a.difference > b.difference) { return 1; }
      if (a.difference < b.difference) { return -1; }
      if (a.first_name < b.first_name) {return -1; }
      if (a.first_name > b.first_name) {return 1; }
      return 0;
    }
    this.allMembers.sort(compare);
    if (this.tournament.finished && this.allMembers && this.allMembers.length) {
      this.param = { value : this.allMembers[0].sumPoints };
    }

    if (this.isPrettyVersion) {
      this.prettyPDF();
    } else {
      this.pdf();
    }
  }

  getBase64ImageFromURL(url, isBanner = false) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        if (isBanner) {
          const lazzyURL = 'https://raw.githubusercontent.com/VictorVHTI/imgQPRO/master/Plantillas/lazzy_url.png';
          resolve(this.getBase64ImageFromURL(lazzyURL));
        } else {
          this.showProgressBar = false;
          reject(error);
        }
      };

      img.src = url;
    });
  }

  async prettyPDF() {
    try {
      const useLandscape = this.quinielas.length > 20;
      const grassColor = '#2CAB80';

      let title = {
        style: {fillColor: '#eeeeee'},
        color: '#444',
        layout: 'noBorders',
        table: {
          widths: ['auto', '*', this.tournament.description ? '*' : 'auto'],
          headerRows: 2,
          keepWithHeaderRows: 1,
          body: [
            [ 
              {
                image: await this.getBase64ImageFromURL(this.tournament.picture, true),
                width: 200,
                height: 113,
                rowSpan: 3,
                margin: [10, 5, 0, 5]
              },
              {
                text: this.tournament.name, style: {fontSize: 13, bold: true}, margin: [5, 20],
              },
              {
                text: this.tournament.description ? 'Descripción:\n\n' + this.tournament.description : '',
                style:{fontSize: 9},
                rowSpan: 3,
                margin: [5, 5]
              }
            ],
            ['', { text: `${this.tournament.quinielas.length} Quinielas.`, margin: [5, 10] }],
            ['', { text: `Admin: ${this.tournament.createdBy.first_name} ${this.tournament.createdBy.last_name}`,
              alignment: 'right',
              margin: [5, 5],
              style: {fontSize: 10}
            }]
          ]
        }
      };

      let widthsHeader = [];
      let members = [];
      let hQuinielas = [];

      const header = (text) => {
        return {
          text: text,
          margin: [0, 10],
          bold: true,
          color: 'white',
          fillColor: grassColor,
          alignment: 'center',
        };
      }

      this.quinielas.forEach((q) => {
        widthsHeader.push('auto');
        hQuinielas.push(header(q.members[0].quiniela_name));
      });

      if (this.tournament.tiebreaker) {
        widthsHeader.push('auto');
        hQuinielas.push(header(`Goles\n${this.totalGoals}`));
      }

      const grayColor = '#eeeeee';
      this.allMembers.forEach((m, i) => {
        let memberRow = [];
        memberRow.push({ text: (i + 1).toString(), alignment: 'center' });
        memberRow.push({ text: m.sumPoints, alignment: 'center' });
        memberRow.push(this.quinielas.length < 9 
          ? m.first_name + ' ' + m.last_name
          : (m.first_name + ' ' + m.last_name).slice(0, 10)
        );

        for (let index = 0; index < this.quinielas.length; index++) {
          const point = m.tablePoints[index] ? m.tablePoints[index].toString() : '-';
          const isMax = (this.maxPoints[index] === m.tablePoints[index] && m.tablePoints[index] !== 0);
          memberRow.push({
            text: point,
            alignment: 'center',
            fillColor: isMax ? '#8EC5B2' : grayColor,
            color: isMax ? 'white' : '#444', 
            bold: isMax
          });
        }

        if (this.tournament.tiebreaker) {
          memberRow.push({ text: `${m.sumGoals} / ${m.difference}`, alignment: 'center' });
        }

        members.push(memberRow);
      });

      let contentTable = {
        style: {fillColor: grayColor},
        color: '#444',
        fontSize: 7.5,
        layout: {
          hLineWidth: (i, node) => 2.5,
          vLineWidth: (i, node) => 2.5,
          hLineColor: (i, node) => 'white',
          vLineColor: (i, node) => 'white',
        },
        table: {
          widths: ['auto', 'auto', '*'].concat(widthsHeader),
          headerRows: 1,
          keepWithHeaderRows: 1,
          body: [
            [
              header('#'),
              header('Pts.'),
              header('Participantes'),
            ].concat(hQuinielas),
          ].concat(members)
        }
      };

      var docDefinition = {
        pageOrientation: useLandscape ? 'landscape' : 'portrait',
        pageMargins: [8, 8, 8, 8],
        pageSize: 'LEGAL',
        content: [
          title,
          {text: '', margin: [5, 10]},
          contentTable,
          {text:'Power by: Quiniela PRO', style: {fontSize: 10}, alignment: 'right', bold: true, margin: [0,15, 0, 1]},
          {text:'www.quinielapro.com', style: {fontSize: 9}, alignment: 'right'},
        ],
      };

      const fileName = this.tournament.name + '_' + this.tournament.createdBy.first_name + '_' + this.tournament.createdBy.last_name;
      pdfMake.createPdf(docDefinition).download(fileName);
      this.showProgressBar = false;
    } catch (error) {
      this.errorPDF = true;
    }
  }

  pdf() {
    const title = this.tournament.name + ' | ' + this.tournament.createdBy.first_name + ' ' + this.tournament.createdBy.last_name + 
      ' | ' + this.tournament.quinielas.length + ' quinielas.';

    let _tournament = {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto'],
        body: [
          ['#', 'Pts', 'Participantes']
        ]
      };

    this.quinielas.forEach((q) => {
      _tournament.widths.push('auto');
      _tournament.body[0].push(q.members[0].quiniela_name);
    });

    if (this.tournament.tiebreaker) {
      _tournament.widths.push('auto');
      _tournament.body[0].push(`Goles \n ${this.totalGoals}`);
    }

    this.allMembers.forEach((m, i) => {
      let memberRow = [];
      memberRow.push({ text: (i + 1).toString(), alignment: 'center' });
      memberRow.push({ text: m.sumPoints, alignment: 'center' });
      memberRow.push((m.first_name + ' ' + m.last_name).slice(0, 10));

      for (let index = 0; index < this.quinielas.length; index++) {
        const point = m.tablePoints[index] ? m.tablePoints[index].toString() : '-';
        const color = (this.maxPoints[index] === m.tablePoints[index] && m.tablePoints[index] !== 0) ?
          'green' : '';
        const bold = this.maxPoints[index] === m.tablePoints[index] && m.tablePoints[index] !== 0;
        memberRow.push({ text: point, alignment: 'center', color: color, bold: bold });
      }

      if (this.tournament.tiebreaker) {
        memberRow.push({ text: `${m.sumGoals} / ${m.difference}`, alignment: 'center' });
      }
      _tournament.body.push(memberRow);
    });

    const docDefinition = {
      pageOrientation: 'portrait', pageMargins: [5, 5, 5, 5],
      pageSize: 'LEGAL',
      content: [
        title,
        {
          fontSize: 5,
          layout: 'lightHorizontalLines',
          table: _tournament,
          margin: [0.5, 0.5, 0.5, 0.5]
        }
      ],
    };

    docDefinition.content.push('www.quinielapro.com');
    const fileName = this.tournament.name + '_' + this.tournament.createdBy.first_name + '_' + this.tournament.createdBy.last_name;
    pdfMake.createPdf(docDefinition).download(fileName);
    this.showProgressBar = false;
  }

}
