import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NewsComponent } from './pages/news/news.component';
import { QuinielaDetailsComponent } from './pages/quiniela-details/quiniela-details.component';
import { CreditsComponent } from './pages/credits/credits.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { VirtualGoalsComponent } from './pages/virtual-goals/virtual-goals.component';
import { TournamentsComponent } from './pages/tournaments/tournaments.component';
import { DetailsComponent } from './pages/tournaments/details/details.component';
import { OfficialDetailsComponent } from './pages/tournaments/official-details/official-details.component';
import { QuinielaPDFComponent } from './pages/quiniela-pdf/quiniela-pdf.component';
import { TournamentPDFComponent } from './pages/tournament-pdf/tournament-pdf.component'

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/t/:pt', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'news', component: NewsComponent },
  { path: 'quiniela/:id', component: QuinielaDetailsComponent },
  { path: 'quinielapdf/:id/:pt', component: QuinielaPDFComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'virtual-goals', component: VirtualGoalsComponent },
  { path: 'tournaments', component: TournamentsComponent },
  { path: 'tournaments/:id', component: DetailsComponent },
  { path: 'tournamentpdf/:id/:pt/:vers', component: TournamentPDFComponent },
  { path: 'tournaments/official/:id/:name', component: OfficialDetailsComponent },

  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }